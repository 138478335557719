import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";

import {
  Hero,
  Offer,
  News,
  Guide,
  Why,
  Opinions,
  Gallery,
  Content,
} from "page_components/main";
import InfoBanner from "components/InfoBanner";

import { getWpPage } from "utils";

const Main = ({ pageContext, location }) => {
  const pageData = getWpPage(pageContext.data, "Main", true);
  const seo = pageContext.data.seo;

  return (
    <Layout rework>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Hero {...pageData?.hero} />
      <Offer {...pageData?.offer} />
      <News {...pageData?.news} />
      <Guide {...pageData?.guide} />
      <InfoBanner
        image={pageData?.banner.image?.gatsbyImage}
        title={pageData?.banner.content.title}
        url={pageData?.banner.content.link}
      />
      <Why {...pageData?.why} />
      <Opinions />
      <Gallery />
      <Content {...pageData?.info} search={location?.search} />
    </Layout>
  );
};

export default Main;
